import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

Vue.filter('tel', function (tel) { // +41311112233 => 031 111 22 33
  if (!tel || tel.substr(0, 3) !== '+41') return tel
  return '0' + tel.substr(3, 2) + ' ' + tel.substr(5, 3) + ' ' + tel.substr(8, 2) + ' ' + tel.substr(10, 2)
})

Vue.use(VueTelInput, { // https://iamstevendao.github.io/vue-tel-input/documentation/props.html
  defaultCountry: 'CH',
  autoDefaultCountry: false,
  inputOptions: {
    required: true,
    placeholder: '',
    autocomplete: 'off'
  }
})
