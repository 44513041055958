import Vue from 'vue'
import cfg from '../../cfg'
import LogRocket from 'logrocket'
import { Capacitor } from '@capacitor/core'

if (cfg.logRocket && process.env.NODE_ENV === 'production') {
  LogRocket.init(cfg.logRocket)
  LogRocket.log('platform', Capacitor.getPlatform())
  LogRocket.log('useragent', window.navigator.userAgent)

  Vue.prototype.$logRocket = LogRocket
} else {
  Vue.prototype.$logRocket = { log: () => {}, identify: () => {} }
}
