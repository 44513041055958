import Vue from 'vue'
import VueImgix from 'vue-imgix'
import cfg from '../../cfg'

if (cfg.imgix?.domain) {
  Vue.use(VueImgix, {
    domain: cfg.imgix.domain,
    defaultIxParams: {
      auto: 'format'
    }
  })
}
