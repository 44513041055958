import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import { Capacitor } from '@capacitor/core'

const LOCALHOST = location.hostname === 'localhost'

const isNative = Capacitor.isNativePlatform()
const platform = Capacitor.getPlatform()
const isAndroid = platform === 'android'
const isIOS = platform === 'ios'
const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)

const USE_EMULATOR_SUITE = Vue.$cfg.firebaseEmulator && LOCALHOST && !isNative
const FUNCTIONS_REGION = Vue.$cfg.firebaseRegion


const app = firebase.initializeApp(Vue.$cfg.firebaseConfig)

const auth = firebase.auth()
if (USE_EMULATOR_SUITE) {
  auth.useEmulator('http://localhost:9099')
}
auth.useDeviceLanguage()

const db = firebase.firestore()
if (isIOS) {
  db.settings({
    experimentalForceLongPolling: true // without this, Cap/iOS took 10s for first Firestore read (settings/core)
  })
}
if (USE_EMULATOR_SUITE) {
  db.useEmulator('localhost', 5002)
}
// Let's give iOS another chance and try persistence there
// if (isAndroid || (!LOCALHOST && !isSafari && !isIOS)) { // on Cap/Android (localhost!), or web, but not locally (dev)
if (isAndroid || isIOS || (!LOCALHOST && !isSafari)) { // on capacitor (localhost!), or web, but not locally (dev) or Safari (auth / multitabs issues)
  db.enablePersistence({
    synchronizeTabs: true
  })
    .then(() => {
      console.log('DB persistence enabled')
    })
    .catch(e => {
      console.error('Error: DB peristence failed', e)
    })
}

const fn = app.functions(FUNCTIONS_REGION)
if (USE_EMULATOR_SUITE) {
  fn.useEmulator('localhost', 5001)
}

const fs = firebase.storage()
if (USE_EMULATOR_SUITE) {
  fs.useEmulator('localhost', 9199)
}

const $fb = {
  fb: firebase,
  auth,
  db,
  fn,
  fs
}

Vue.prototype.$fb = $fb

export default $fb
