<template>
  <home v-if="$root.ready && mode === 'app'">
    <v-snackbar v-model="showUpdateSnack" :timeout="-1">
      {{ $t(updating ? 'updating' : 'update_available') }}
      <template v-slot:action>
        <v-btn v-if="!updating" color="green" text @click="updateApp">OK</v-btn>
      </template>
    </v-snackbar>
  </home>

  <print v-else-if="$root.ready && mode === 'print' && $root.isAdmin"/>

  <v-app v-else-if="$root.userId === false && !$root.native && $vuetify.breakpoint.xs && ($cfg.appUrlGoogle || $cfg.appUrlApple) && !skipNative">
    <v-app-bar app color="primary" dark>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ $cfg.appTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container class="text-center py-15">
        <div v-if="$cfg.appUrlGoogle" class="mb-6"><a :href="$cfg.appUrlGoogle" target="_blank"><img src="/static/google-play-badge.svg" width="200"></a></div>
        <div v-if="$cfg.appUrlApple && $cfg.appUrlApple !== 'PROMOCODES'" class="mb-6"><a :href="$cfg.appUrlApple" target="_blank"><img src="/static/apple-app-store-badge.svg" width="200"></a></div>
        <div v-else-if="$cfg.appUrlApple && $cfg.appUrlApple === 'PROMOCODES'" class="mb-6" @click="showEmailDlg = true"><img src="/static/apple-app-store-badge.svg" width="200"></div>
        <div><v-btn text small @click="skipNative = true">Im Browser verwenden</v-btn></div>
      </v-container>
    </v-main>

    <v-dialog
      :value="showEmailDlg"
      max-width="300"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Apple App Store</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="showEmailDlg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="!appleStoreLink">
          <div class="py-6">Gib deine E-Mail Adresse ein, um Zugang zur iOS App (Apple App Store) zu erhalten:</div>
          <v-text-field v-model="email" label="E-Mail" type="email"/>
        </v-card-text>
        <v-card-text v-else>
          <div class="py-6">
            <div>Klicke auf den Link und dann auf <b>"Einlösen":</b></div>
            <a :href="appleStoreLink" class="my-2">{{ appleStoreLink }}</a>
          </div>
        </v-card-text>
        <v-card-actions v-if="!appleStoreLink">
          <v-spacer/>
          <v-btn color="primary" :disabled="!isEmailValid" :loading="opening" @click="openAppleStore">Zum App Store</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>

  <sign-up-in v-else-if="$root.userId === false"/>

  <v-app v-else>
    <v-overlay :value="true" opacity="0">
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    Home: () => import('@/views/Home'),
    Print: () => import('@/views/Print'),
    SignUpIn: () => import('@/views/SignUpIn'),
    ImgCmp: () => import('@/components/base/ImgCmp')
  },
  data () {
    return {
      mode: null,
      skipNative: false,
      showEmailDlg: false,
      email: null,
      appleStoreLink: null,
      opening: false,
      showUpdateSnack: false,
      updating: false
    }
  },
  computed: {
    isEmailValid () {
      return /\S+@\S+\.\S+/.test(this.email)
    }
  },
  methods: {
    updateApp () {
      this.updating = true
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) {
        this.showUpdateSnack = false
        return
      }
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    async openAppleStore () {
      this.opening = true
      const getAppleStoreLinkFunc = this.$fb.fn.httpsCallable('getAppleStoreLink')
      const response = await getAppleStoreLinkFunc({
        email: this.email
      })
      if (response.data.ok) {
        this.appleStoreLink = response.data.link
        window.open(response.data.link) // does not work on iOS Safari
      } else {
        alert('Fehler beim Öffnen des App Stores')
      }
      this.opening = false
    }
  },
  created () {
    // const urlParams = new URLSearchParams(window.location.search)
    // urlParams.has('mode') ? urlParams.get('mode') : 'app'
    this.mode = window.location.pathname.substr(1) || 'app'
    
    document.addEventListener('swUpdated', (event) => {
      this.registration = event.detail
      this.showUpdateSnack = true
    }, { once: true })

    if (navigator.serviceWorker !== undefined) { // may not be available in private mode or local
      let reloading = false
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // We'll also need to add 'reloading' to our data originally set to false.
        if (reloading) return
        reloading = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
    }
  }
}
</script>
