import Vue from 'vue'
import VueI18n from 'vue-i18n'
import labels from '@/labels.js'

Vue.use(VueI18n)

// define available languages; first is default
const locales = ['de']

// start with browser lang, if available
let locale = locales[0]
const naviLang = navigator.language.substr(0, 2)
if (locales.indexOf(naviLang) !== -1) {
  locale = naviLang
}

// merge labels with cust labels
if (Vue.$cfg.labels) {
  Object.keys(Vue.$cfg.labels).forEach(lang => {
    labels[lang] = {
      ...labels[lang],
      ...Vue.$cfg.labels[lang]
    }
  })
}

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: locales[0],
  silentFallbackWarn: true,
  messages: labels
})

i18n.locales = locales

export default i18n
